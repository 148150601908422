<template>
  <v-data-table
    :headers="headers"
    :items="list"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มอัตราเรทราคาสินค้า(ชิ้น)
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-select
                      :readonly="editedIndex >= 0"
                      v-model="transport_type_id"
                      :items="transport_types"
                      item-text="transport_type_name"
                      item-value="transport_type_id"
                      label="รูปแบบการจัดส่ง"
                    ></v-select>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.transport_type_id.$silentErrors"
                      v-if="v$.transport_type_id.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-btn
                      @click="addRate"
                      class="float-right" color="secondary">+</v-btn>
                  </v-col>
                  <v-col
                     v-if="rates && rates.length > 0"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <table  class="table">
                      <thead>
                      <tr>
                        <th width="30" class="text-center">#</th>
                        <th width="100">จำนวน(กรัม)	</th>
                        <th width="150">ราคา</th>
                        <th width="50"></th>
                      </tr>
                        </thead>
                      <tbody>
                      <!--            pattern_set_prices-->
                      <tr v-for="(item,index) in rates">
                        <td class="text-center"> {{index+1}}</td>
                        <td>

                          <v-text-field
                            v-model="item.amount"
                            type="number"
                            min="0"
                            placeholder="ราคาพิเศษสำหรับลายร้าน"
                          ></v-text-field>
                        </td>
                        <td class="text-center">
                          <v-text-field
                            class="text-center"
                            v-model="item.price"
                            type="number"
                            min="0"
                            placeholder="ราคาพิเศษสำหรับลายร้าน"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-icon
                            @click="deleteRate(index)"
                            class="mr-2"
                            small
                            color="danger"
                          >

                            {{ icons.mdiDelete }}
                          </v-icon>
                        </td>

                      </tr>
                      </tbody>
                    </table>

                    <hr class="mb-5" style="border: 0.5px solid lightgrey">

                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.rates.$silentErrors"
                      v-if="v$.rates.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="rates.length > 0"
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_transport_expense_rate from '../../../services/transportExpenseRate'
import instance_transport_type from '../../../services/transportType'
import Swal from "sweetalert2";
import {useVuelidate} from "@vuelidate/core";
import {email, minLength, required} from "@vuelidate/validators";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    transport_types: [],
    list: [],
    rates: [],
    dialog: false,
    transport_type_id: '',
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'ประเภทสินค้า', value: 'transport_type.transport_type_name' , sortable: false},
      { text: 'แก้ไขข้อมูล', value: 'actions'  , sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      productPoint_id : '',
      image_path : '',
      productPoint_name : '',
      detail : '',
      stock : 0,
      amount : 0,
      score : 0,
    },
    defaultItem: {
      productPoint_id : '',
      image_path : '',
      productPoint_name : '',
      detail : '',
      stock : 0,
      amount : 0,
      score : 0,
    },
  }),
  validations() {
    return {
      transport_type_id : { required },
      rates : { required },
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม อัตราค่าขนส่ง' : 'แก้ไข อัตราค่าขนส่ง'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getTransportExpenseRate()
      },
    },
    search : {
      handler(){
        this.getTransportExpenseRate()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getTransportExpenseRate()
    this.getTransportType()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true

      instance_transport_expense_rate.show(this.editedItem.id).then(res => {
        this.transport_type_id = res.data.transport_type;
        this.rates = res.data.data
      }).catch(err => {

      })
    },
    deleteItem(item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    deleteRate(index) {

      this.rates.splice(index, 1)
    },
    // updateStatusPackage(id){
    //   instance_transport_expense_rate.packageUpdateStatus(id).then(res => {
    //
    //   }).catch(err => {
    //
    //   });
    // },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_transport_expense_rate.destroy(this.editedItem).then(res => {
          setTimeout(function () {
            Swal.fire({
              icon: 'success',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500
            })
          },150)
        this.getTransportExpenseRate()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
      this.closeDelete()
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.editedItem = { ...this.defaultItem }
      this.rates = [];
      this.transport_type_id = '';
      this.editedIndex = -1
      // this.$nextTick(() => {
      //   this.editedItem = { ...this.defaultItem }
      //   this.editedIndex = -1
      // })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {

        var data = {
          id : this.editedItem.id,
          transport_type_id : this.transport_type_id,
          data : this.rates
        }
        instance_transport_expense_rate.update(data)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
          // Object.assign(this.packages[this.editedIndex], this.editedItem)
            this.getTransportExpenseRate()
            this.close()
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {

        var data = {
          transport_type_id : this.transport_type_id,
          data : this.rates
        }
        instance_transport_expense_rate.store(data)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
            this.getTransportExpenseRate()
            this.close()
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getTransportType() {
      this.$store.state.isLoading = true
      instance_transport_type.get().then(res => {
        this.transport_types = res.data;
        this.$store.state.isLoading = false
      }).catch(err => {

        this.$store.state.isLoading = false
      });
    },
    getTransportExpenseRate() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_transport_expense_rate.get(data).then(res => {
        this.list = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
    addRate(){
      var tmp =  {
        id : '',
        transport_expense_rate_subs_id : '',
        amount : 0,
        price : 0,
      }
      this.rates.push(tmp)
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
