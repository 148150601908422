/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  get(data) {

    return api.get(`transport_expense_rate/list?search=${data.search}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  show(id) {

    return api.get(`transport_expense_rate/show/${id}`)
  },

  destroy(data) {

    return api.delete(`transport_expense_rate/delete/${data.id}`)
  },

  store(data) {
    let getFormData = data

    let params = new FormData();

    params.append('transport_type_id', getFormData['transport_type_id']);
    for (var key in getFormData.data) {
      console.log(getFormData.data)
      if (getFormData.data.hasOwnProperty(key)) {
        if (getFormData.data[key]['price_rate_id'] && getFormData.data[key]['price_rate_id']) {

        }
          params.append('price_rate_id[]', getFormData.data[key]['price_rate_id'] ? getFormData.data[key]['price_rate_id'] : '');
          params.append('amount[]', getFormData.data[key]['amount']);
          params.append('price[]', getFormData.data[key]['price']);
        }
      }
      // let config = {
      //   header: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }
      return api.post(`transport_expense_rate/store`, params, {})
    }
  ,

    update(data)
    {
      let getFormData = data

      let params = new FormData();
      params.append('_method', 'PUT');
      params.append('transport_type_id', getFormData['transport_type_id']);
      for (var key in getFormData.data) {
        if (getFormData.data.hasOwnProperty(key)) {

          if (getFormData.data[key]['id'] && getFormData.data[key]['id'] != undefined) {
            params.append('transport_expense_rate_subs_id[]', getFormData.data[key]['id']);
          }else{
            params.append('transport_expense_rate_subs_id[]', '');
          }
          params.append('amount[]', getFormData.data[key]['amount']);
          params.append('price[]', getFormData.data[key]['price']);
        }
      }
      // let config = {
      //   header: {
      //     'Content-Type': 'multipart/form-data'
      //   }
      // }
      return api.post(`transport_expense_rate/update/${data.id}`, params, {})
    }
  ,


  }
